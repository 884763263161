
// Utilities
import { sync, get } from 'vuex-pathify';

export default {
  name: 'AppBar',

  data: () => ({
    items: [
      {
        text: 'Privacy',
        to: { name: 'privacy' }
      },
      {
        text: 'Terms',
        to: { name: 'terms' }
      }
    ]
  }),

  computed: {
    ...(sync('app/*') as any),
    drawer: sync('app/drawer')
  }
};
